import systemPropTypes from '@styled-system/prop-types'

import styled from 'styled-components'
import { layout, border, shadow, position, space, color, background, grid } from 'styled-system'

export const SptBox = styled.div`
  ${layout};
  ${border};
  ${shadow};
  ${position};
  ${space};
  ${color};
  ${background};
  ${grid};
`

SptBox.displayName = 'SptBox'

SptBox.propTypes = {
  ...systemPropTypes.layout,
  ...systemPropTypes.border,
  ...systemPropTypes.shadow,
  ...systemPropTypes.position,
  ...systemPropTypes.space,
  ...systemPropTypes.color
}

SptBox.defaultProps = {}
