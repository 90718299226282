export default {
  geral: {
    app: 'MedPlus Programación en línea',
    proximo: 'Próximo',
    voltar: 'Vuelve',
    confirmar: 'Confirmar',
    data: 'Fecha',
    components: {
      areaAtuacao: 'Área de actuación',
      unidadeAtendimento: 'Unidad de Cuidados',
      horarios: 'Horarios',
      agendar: 'Para programar',
      entre: 'Entre',
      cadastrese: 'Registrarse',
      ou: 'o',
      fotoAdicionadaSucesso: 'Foto agregada con éxito!',
      fotoRemovidaSucesso: 'Foto eliminada correctamente.',
      raca: 'Raza',
      particular: 'Privado',
      tipoConsulta: 'Tipo de consulta',
      presencial: 'Presencial',
      teleconsulta: 'Teleconsulta'
    },
    navegacao: {
      titulo: 'Programación en línea',
      agendamento: 'Planificación',
      favoritos: 'Favoritos',
      agendar: 'Para programar',
      historico: 'Histórico',
      laudos: 'Informes',
      exames: 'Exámenes',
      teleconsultas: 'Teleconsultas'
    }
  },

  modulos: {
    agendamento: {
      especialidade: {
        titulo: 'Especialidades',
        naoEncontrado: 'Ningún resultado encontrado',
        naoEncontradoDetalhes: 'Utilice diferentes palabras clave o elimine los filtros de búsqueda'
      },

      clinica: {
        titulo: 'Clinicas',
        endereco: 'Dirección',
        bairro: 'Vecindario',
        cidade: 'Ciudad',
        telefone: 'Teléfono',
        distancia: 'Esta clínica está a metros de distancia {{metros}} desde tu ubicación actual'
      },

      operadoras: {
        titulo: 'Operadores de salud',
        tituloOperadora: 'Operador Privado o Sanitario',
        subtituloOperadora: 'Seleccione si la cita será Privada o por Operador de Salud',
        operadora: 'Operador de salud',
        plano: 'Plano'
      },

      profissionais: {
        titulo: 'Horarios y Profesionales',
        turnos: {
          madrugada: 'Amanecer',
          manha: 'Mañana',
          tarde: 'Noche',
          noite: 'Noche'
        },
        profissional: 'Profesional',
        areaAtuacao: 'Áreas de actuación',
        turno: 'Cambio',
        filtros: 'Filtros',
        filtrar: 'Filtrar'
      },

      paciente: {
        titulo: 'Paciente',
        selecionar: 'Seleccione',
        adicionarPaciente: 'agregar nuevo paciente',
        ok: 'ok',
        cadastroPaciente: {
          titulo: 'Nuevo dependiente',
          subtitulo: 'Datos Dependientes',
          cpf: 'CPF',
          nome: 'Nombre completo',
          sexo: 'Sexo',
          dataNascimento: 'Fecha de nacimiento',
          celular: 'Teléfono celular',
          telefone: 'Teléfono fijo',
          email: 'Correo electrónico',
          parentesco: 'Parentesco',
          cidade: 'Ciudad',
          pais: 'Padres',
          uf: 'UF',
          tipoLogradouro: 'Tipo de dirección',
          buttonNovoCadastro: 'registrar nuevo paciente',
          buttonAdicionarCadastro: 'agregar registro',
          successo: '¡Paciente vinculado con éxito!',
          validacoes: {
            cpf: 'CPF no válido',
            cpfObrigatorio: 'Informar al CPF',
            cpfExistente: 'Ya hay un paciente registrado en este CPF',
            nome: 'Ingrese su nombre completo',
            dataNascimento: 'Ingrese fecha de nacimiento',
            dataNascimentoMaior: 'Fecha de nacimiento mayor que la fecha actual',
            celular: 'Informar al celular',
            email: 'Email inválido',
            parentesco: 'Informar la relación',
            emailObrigatorio: 'Ingrese el correo electrónico'
          }
        }
      },

      turnos: {
        manha: 'Mañana',
        tarde: 'Noche',
        noite: 'Noche',
        madrugada: 'Amanecer'
      },

      confirmacao: {
        titulo: 'Confirmación',
        subtitulo: '¿Todo bien para tu horario?',
        especialidade: 'Especialidad',
        clinica: 'Clínica',
        profissional: 'Profesional',
        dataHora: 'Fecha y hora',
        operadoraPlano: 'Operador de planes y salud',
        paciente: 'Paciente',
        orientacao: 'Pautas de la clínica',
        verMais: 'Ver más',
        verMenos: 'Ver menos'
      },
      questionario: {
        titulo: 'Cuestionario de preconsulta',
        subtitulo: 'Responda el cuestionario de preconsulta para agilizar su servicio',
        buttonResponder: 'Respuesta',
        buttonSalvar: 'Ahorrar',
        successo: '¡Prueba guardada correctamente!',
        erro: 'Atención, para confirmar la cita, cumplimentar el cuestionario de preconsulta'
      },

      conclusao: {
        titulo: '¡Felicidades! Has completado tu cita.',
        subtitulo: '¿Qué quieres hacer ahora?',
        novo: 'Nueva cita',
        historico: 'Historial de programación',
        perfil: 'Actualizar mi perfil',
        pagamento: 'Realizar pago',
        pagamentoRealizado: '¡Pago exitoso!',
        subtituloPagamento: '¿Quiere pagar su cita? Para ello, haga clic en el botón de abajo: '
      }
    },
    login: {
      login: 'Ingrese correo electrónico o CPF',
      senha: 'Introduce la contraseña',
      button: 'Acceso',
      link: 'Olvide mi contraseña',
      titulo: '¿Primera vez en la programación en línea?',
      subtitulo: ' Cree una cuenta gratuita y reserve citas en cualquier momento y lugar, ¡sin esperas!',
      buttonCriar: 'Crea una cuenta',
      facaLogin: 'Acceso'
    },
    cadastroConta: {
      titulo: 'Registrar Cuenta',
      subtitulo: 'Crea tu cuenta, es solo una vez y puedes programar cuando quieras.',
      cpf: 'CPF',
      nome: 'Nombre completo',
      sexo: 'Sexo',
      dataAniversario: 'Fecha de nacimiento',
      telefoneCelular: 'Teléfono celular',
      telefoneFixo: 'Teléfono fijo',
      midia: '¿Cómo conociste la clínica?',
      email: 'Correo electrónico',
      senha: 'Contraseña',
      confirmacaoSenha: 'Confirmación de contraseña',
      buttonVoltar: 'Vuelve',
      buttoCriar: 'Crea una cuenta',
      tituloPoliticaPrivacidade: 'Política de privacidad',
      buttonAceitoPoliticaPrivacidade: 'Acepto la politica de privacidad',
      validacoes: {
        cpf: 'CPF no válido',
        cpfObrigatorio: 'Informar al CPF',
        nome: 'Ingrese su nombre completo',
        dataAniversario: 'Ingrese fecha de nacimiento',
        dataAniversarioMaior: 'Fecha de nacimiento mayor que la fecha actual',
        telefoneCelular: 'Informar al celular',
        email: 'Email inválido',
        sexo: 'Informar el género',
        emailObrigatorio: 'Ingrese el correo electrónico',
        senha: 'Introduce la contraseña',
        confirmacaoSenha: 'Ingrese la confirmación de la contraseña',
        senhasDiferentes: 'Las contraseñas no coinciden',
        senhaInvalida: 'Contraseña invalida'
      },
      sendCode: {
        titulo: 'Necesitamos validar su cuenta',
        subtitulo: '¿Cómo desea obtener el código de validación?',
        button: 'Mandar',
        email: 'Enviar mensaje al correo electrónico',
        sms: 'Enviar mensaje a celular',
        codigoEnviadoSucesso: '¡Código enviado con éxito!'
      }
    },
    forgot: {
      titulo: 'Redefinir contraseña',
      subtitulo: 'Ingrese su correo electrónico o número de teléfono y restableceremos su contraseña.',
      placeholderEmail: 'Introduzca su correo electrónico aquí',
      placeholderCelular: 'Ingresa tu celular aquí',
      button: 'Continuar',
      buttonCancelar: 'Cancelar',
      emailValido: 'Introduzca una dirección de correo electrónico válida',
      ou: 'o'
    },
    sendCode: {
      titulo: 'Nosotros necesitamos verificar tu identidad',
      subtitulo: '¿Cómo desea recibir el código de seguridad para restablecer su contraseña?',
      button: 'Mandar',
      buttonOutlined: '¿No y tu?',
      email: 'Envíe el código por correo electrónico',
      sms: 'Enviar código por SMS'
    },
    authVerifyCode: {
      titulo: 'Revisar su ',
      subtitulo: 'Enviamos un código de confirmación al ',
      email: 'Email',
      telefone: 'teléfono',
      sms: 'sms',
      placeholder: 'Ingrese el código de validación aquí',
      button: 'Confirmar',
      auxilio: 'Si no recibe el ',
      auxilio2: ', puede enviar otra solicitud a:',
      buttonOutlined: 'Reenviar codigo',
      buttonTentar: 'Prueba de otra manera',
      obrigatorio: 'Ingrese el código de validación',
      sucesso: '¡Código reenviado con éxito!'
    },
    changePassword: {
      titulo: 'Cambiar la contraseña',
      subtitulo: 'Ingrese la nueva contraseña de acceso deseada a continuación',
      senha: 'Contraseña',
      confirmacaoSenha: 'Confirmación de contraseña',
      button: 'Confirmar',
      senhaAlterada: '¡Contraseña alterada con éxito!',
      validacoes: {
        senha: 'Introduce la contraseña',
        minimoCaracteres: 'La contraseña debe tener al menos 8 caracteres',
        confirmacaoSenha: 'Informar la confirmación de la contraseña',
        senhasDiferentes: 'Las contraseñas no coinciden',
        senhaInvalida: 'Contraseña invalida'
      }
    },
    historico: {
      titulo: 'Histórico',
      clinica: 'Clínica',
      profissional: 'Profesional',
      especialidade: 'Especialidad',
      duracao: 'Duración',
      status: 'Estado',
      operadoraPlano: 'Operador de planes y salud',
      paciente: 'Paciente',
      tipoAgendamento: 'Tipo de programación',
      orientacoesExames: 'Directrices del examen',
      orientacoesClinica: 'Pautas de la clínica',
      valor: 'Valor',
      cancelar: 'Cancelar',
      nenhumAgendamento: 'No se encontró cita',
      desejaCancelar: '¿De verdad quieres cancelar la cita?',
      voltar: 'Vuelve',
      confirmar: 'Confirmar',
      agendamentoCancelado: '¡Cita cancelada con éxito!'
    },
    expiredPassword: {
      titulo: 'Solicitud de cambio de contraseña',
      subtitulo:
        'De acuerdo con las resoluciones del Consejo Federal de Medicina, debe cambiar su contraseña en un plazo de 30 a 180 días o cuando lo solicite un administrador.',
      senhaAtual: 'Contraseña actual',
      novaSenha: 'Nueva contraseña',
      confirmacaoSenha: 'Confirmación de contraseña',
      solicitarTroca: 'Solicitar cambio de contraseña',
      acessar: 'Acceso',
      senhaAlterada: '¡Contraseña alterada con éxito!',
      validacoes: {
        senhaAtual: 'Introducir la contraseña actual',
        novaSenha: 'Ingrese la nueva contraseña',
        confirmacaoSenha: 'Ingrese la confirmación de la contraseña',
        senhasDiferentes: 'Las contraseñas no coinciden',
        solicitarTroca: 'Ingrese los días para solicitar un nuevo cambio de contraseña'
      }
    },
    perfil: {
      salvar: 'Ahorrar',
      cancelar: 'Cancelar',
      sucesso: 'Datos guardados exitosamente',
      dadosBasicos: {
        tab: 'datos básicos',
        tituloDados: 'DADOS',
        subtituloDados: 'Datos clave del cliente',
        nome: 'Nombre completo',
        dataNascimento: 'Fecha de nacimiento',
        cpf: 'CPF',
        nomeSocial: 'Nombre social',
        tituloContato: 'CONTACTO',
        subtituloContato: 'Informar al contacto principal',
        celular: 'Teléfono celular',
        telefone: 'Teléfono fijo',
        email: 'Correo electrónico',
        naoReceberSMS: 'No quiero recibir SMS corporativos',
        naoReceberSMSMarketing: 'No quiero recibir SMS Marketing',
        naoReceberEmail: 'No quiero recibir correos electrónicos ',
        tituloNecessidadesEspeciais: 'NECESIDADES ESPECIALES',
        subtituloNecessidadesEspeciais: '¿Tiene alguna necesidad especial?',
        tituloResponsaveis: 'RESPONSABLE',
        tituloDependentes: 'DEPENDIENTES',
        remover: 'Para eliminar',
        desejaRemover: '¿Realmente quieres eliminar?',
        responsavelRemovido: 'Responsable eliminado con éxito',
        dependenteRemovido: 'Dependiente eliminado con éxito',
        necessidadesEspeciais: {
          obeso: 'Obeso',
          gestante: 'Embarazada',
          lactanteCriancaColo: 'Infante y / o infante',
          deficiencia: 'Discapacitados físicos',
          idoso: 'Anciano'
        },
        validacoes: {
          cpf: 'CPF no válido',
          cpfObrigatorio: 'Informar al CPF',
          nome: 'Ingrese su nombre completo',
          dataNascimento: 'Ingrese fecha de nacimiento',
          dataNascimentoMaior: 'Fecha de nacimiento mayor que la fecha actual'
        }
      },
      documentos: {
        selecioneGrupo: 'Seleccione un grupo para agregar',
        tituloRegistroGeral: 'REGISTRO GENERAL',
        rg: 'RG',
        emissor: 'Editor',
        rgEmissao: 'Fecha de envío',
        nomeMae: 'Nombre de la madre',
        nomePai: 'Nombre del Padre',
        naturalidade: 'Naturalidad',
        tituloPassaporte: 'PASAPORTE',
        pais: 'País',
        numeroPassaporte: 'Pasaporte',
        dataEntradaBrasil: 'Fecha de entrada',
        tituloEscolaridade: 'ENSEÑANZA',
        escolaridade: 'Educación',
        tituloTituloEleitor: 'TÍTULO DE ELECTOR',
        tituloEleitor: 'Título de elector',
        zonaEleitor: 'Zona electoral',
        sessaoEleitor: 'Sección'
      },
      endereco: {
        tituloDados: 'DADO',
        cep: 'Código postal',
        pais: 'País',
        cidade: 'Ciudad',
        uf: 'UF',
        logradouro: 'Lugar público',
        tipoLogradouro: 'Tipo de calle',
        endereco: 'Dirección',
        numero: 'Número',
        bairro: 'Vecindario',
        complemento: 'Complemento'
      },
      acesso: {
        tituloAlterarSenha: 'CAMBIAR CONTRASEÑA',
        senhaAtual: 'Contraseña actual',
        novaSenha: 'Nueva contraseña',
        confirmacaoSenha: 'Confirmación de contraseña',
        alterarSenha: 'CAMBIAR CONTRASEÑA',
        tituloTrocaSenha: 'CAMBIAR LA CONTRASEÑA',
        solicitarTrocaSenha: 'Solicitar cambio de contraseña',
        tituloDadosAcessoPortal: 'DATOS DE ACCESO',
        email: 'Correo electrónico',
        cpf: 'CPF',
        inativarAcessoPortal: 'DESACTIVAR ACCESO INICIAR SESIÓN',
        mensagemInativarAcessoPortal:
          'Estimado paciente, al desactivar el inicio de sesión ya no podrá concertar citas con este usuario. ¿Realmente desea deshabilitar el inicio de sesión?',
        inativar: 'Inactivar',
        solicitarNovaSenhaEmDias: {
          30: '30 días',
          60: '60 días',
          90: '90 días',
          180: '180 días'
        },
        validacoes: {
          senhaAtual: 'Introducir la contraseña actual',
          novaSenha: 'Ingrese la nueva contraseña',
          confirmacaoSenha: 'Ingrese la confirmación de la contraseña',
          senhasDiferentes: 'Las contraseñas no coinciden',
          solicitarTroca: 'Ingrese los días para solicitar un nuevo cambio de contraseña',
          senhaAlterada: '¡Contraseña alterada con éxito!',
          senhaInvalida: 'Contraseña invalida'
        }
      }
    },
    teleconsultas: {
      teleconsulta: 'Teleconsulta'
    }
  }
}
