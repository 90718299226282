import moment from 'moment'
import * as Yup from 'yup'

// eslint-disable-next-line import/no-extraneous-dependencies
import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import * as validations from '../helpers/validations'

Yup.addMethod(Yup.string, 'cpf', function cpfValidator(message = 'CPF inválido') {
  return this.test('cpf', message, validations.isValidCPF)
})

Yup.addMethod(Yup.string, 'cnpj', function cnpjValidator(message = 'CNPJ inválido') {
  return this.test('cnpj', message, validations.isValidCNPJ)
})

Yup.addMethod(Yup.string, 'passwordStrength', function passwordStrengthValidator(message = 'Senha fraca') {
  return this.test('passwordStrength', message, validations.isPasswordStrength)
})

Yup.addMethod(Yup.date, 'minDateToday', function minDateTodayValidator(message = 'Não pode ser menor que hoje') {
  return this.test('minDateToday', message, validations.dateIsGreaterThanToday)
})

Yup.addMethod(Yup.date, 'maxDateToday', function maxDateTodayValidator(message = 'Não pode ser maior que hoje') {
  return this.test('maxDateToday', message, validations.dateIsLessThanToday)
})

Yup.addMethod(Yup.string, 'hoursOrMinutesIsGreaterThanPattern', function hoursOrMinutesIsGreaterThanPatternValidator(
  message = 'Horas maior que 23 ou minutos maior que 59'
) {
  return this.test('hoursOrMinutesIsGreaterThanPattern', message, validations.hoursOrMinutesIsGreaterThanPattern)
})

Yup.addMethod(Yup.date, 'isValidDate', function isValidDateValidator() {
  return this.transform((_, originalValue) =>
    !originalValue || !originalValue.length
      ? null
      : moment(
          originalValue,
          ['YYYY-MM-DDTHH:mm:ss.SSSS', 'YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD'],
          true
        )
          .startOf('day')
          .toDate()
  )
})

Yup.addMethod(Yup.object, 'isTextArea', function isValidDateValidator() {
  return this.transform((_, originalValue) => {
    if (getSafe(originalValue, 'blocks[0].text')) {
      return originalValue
    }
    return null
  })
})

Yup.addMethod(Yup.string, 'isValidCreditCard', function validCreditCardValidator(
  message = 'Cartão de crédito inválido'
) {
  return this.test('isValidCreditCard', message, validations.isValidCreditCard)
})

Yup.addMethod(Yup.string, 'emailCustom', function isValideEmail() {
  return this.email('Email inválido').matches(
    /^[a-zA-Z0-9|@|.|_|-]+$/,
    'Apenas números, letras e os caracteres (.), (_) e (-) são permitidos'
  )
})

Yup.addMethod(Yup.string, 'isValidExpirationDate', function expirationDateValidator() {
  return this.matches(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/, 'Data de validade incorreta')
})

Yup.addMethod(Yup.string, 'isValidBoleto', function boletoValidator(message = 'Boleto inválido') {
  return this.test('isValidBoleto', message, validations.isValidBoleto)
})
