export default {
  geral: {
    app: 'MedPlus Online Scheduling',
    proximo: 'Next',
    voltar: 'Come back',
    confirmar: 'Confirm',
    data: 'Date',
    components: {
      areaAtuacao: 'Occupation area',
      unidadeAtendimento: 'Care Unit',
      horarios: 'Schedules',
      agendar: 'To schedule',
      entre: 'In between',
      cadastrese: 'Register',
      ou: 'or',
      fotoAdicionadaSucesso: 'Photo successfully added!',
      fotoRemovidaSucesso: 'Photo removed successfully!',
      raca: 'Breed',
      particular: 'Private',
      tipoConsulta: 'Type of query',
      presencial: 'Presential',
      teleconsulta: 'Teleconsultation'
    },
    navegacao: {
      titulo: 'Online Scheduling',
      agendamento: 'Scheduling',
      favoritos: 'Favorites',
      agendar: 'To schedule',
      historico: 'Historic',
      laudos: 'Reports',
      exames: 'Exams',
      teleconsultas: 'Teleconsultations'
    }
  },

  modulos: {
    agendamento: {
      especialidade: {
        titulo: 'Specialties',
        naoEncontrado: 'No results found',
        naoEncontradoDetalhes: 'Use different keywords or remove search filters'
      },

      clinica: {
        titulo: 'Clinics',
        endereco: 'Address',
        bairro: 'Neighborhood',
        cidade: 'City',
        telefone: 'Telephone',
        distancia: 'This clinic is meters {{metros}} from your current location'
      },

      operadoras: {
        titulo: 'Health Operators',
        tituloOperadora: 'Private or Health Operator',
        subtituloOperadora: 'Select whether the appointment will be Private or by Health Operator',
        operadora: 'Operadora de Saúde',
        plano: 'Health Operator'
      },

      profissionais: {
        titulo: 'Schedules and Professionals',
        turnos: {
          madrugada: 'Dawn',
          manha: 'Morning',
          tarde: 'Evening',
          noite: 'Night'
        },
        profissional: 'Professional',
        areaAtuacao: 'Areas of expertise',
        turno: 'Shift',
        filtros: 'Filters',
        filtrar: 'Filter'
      },

      paciente: {
        titulo: 'Patient',
        selecionar: 'Select',
        adicionarPaciente: 'Add new patient',
        ok: 'Ok',
        cadastroPaciente: {
          titulo: 'New Dependent',
          subtitulo: 'Dependent Data',
          cpf: 'CPF',
          nome: 'Full name',
          sexo: 'Sex',
          dataNascimento: 'Date of birth',
          celular: 'Cell phone',
          telefone: 'Landline',
          email: 'Email',
          parentesco: 'Kinship',
          cidade: 'City',
          pais: 'Country',
          uf: 'State',
          tipoLogradouro: 'Address Type',
          buttonNovoCadastro: 'Register new patient',
          buttonAdicionarCadastro: 'Add registration',
          successo: 'Successfully linked patient!',
          validacoes: {
            cpf: 'Invalid CPF',
            cpfObrigatorio: 'Inform the CPF',
            cpfExistente: 'There is already a patient registered with this CPF',
            nome: 'Enter full name',
            dataNascimento: 'Enter date of birth',
            dataNascimentoMaior: 'Date of birth greater than current date',
            celular: 'Inform the cell phone',
            email: 'Invalid email',
            parentesco: 'Inform the relationship',
            emailObrigatorio: 'Enter the email'
          }
        }
      },

      turnos: {
        manha: 'Morning',
        tarde: 'Evening',
        noite: 'Night',
        madrugada: 'Dawn'
      },

      confirmacao: {
        titulo: 'Confirmation',
        subtitulo: 'Everything right for your schedule?',
        especialidade: 'Specialty',
        clinica: 'Clinic',
        profissional: 'Profissional',
        dataHora: 'Date and time',
        operadoraPlano: 'Health and Plan Operator',
        paciente: 'Patient',
        orientacao: 'Clinic guidelines',
        verMais: 'View more',
        verMenos: 'See less'
      },
      questionario: {
        titulo: 'Pre-consultation questionnaire',
        subtitulo: 'Answer the pre-consultation questionnaire to expedite your service',
        buttonResponder: 'Answer',
        buttonSalvar: 'To save',
        successo: 'Quiz successfully saved!',
        erro: 'Attention, to confirm the appointment, fill in the pre-consultation questionnaire'
      },

      conclusao: {
        titulo: 'Congratulations! You have completed your appointment.',
        subtitulo: 'What do you want to do now?',
        novo: 'New schedule',
        historico: 'Scheduling History',
        perfil: 'Update my profile',
        pagamento: 'Make Payment',
        pagamentoRealizado: 'Payment made successfully!',
        subtituloPagamento: 'Do you want to pay for your appointment? For this click on the button below: '
      }
    },
    login: {
      login: 'Enter E-mail or CPF',
      senha: 'Enter the password',
      button: 'Access',
      link: 'I forgot my password',
      titulo: 'First time on Online Scheduling?',
      subtitulo: ' Create a free account and make appointments anytime, anywhere, without waiting!',
      buttonCriar: 'Create an account',
      facaLogin: 'Login'
    },
    cadastroConta: {
      titulo: 'Register account',
      subtitulo: 'Create your account, it s only once and you can schedule whenever you want.',
      cpf: 'CPF',
      nome: 'Full name',
      sexo: 'Sex',
      dataAniversario: 'Date of birth',
      telefoneCelular: 'Cell phone',
      telefoneFixo: 'Landline',
      midia: 'How did you get to know the clinic?',
      email: 'Email',
      senha: 'Password',
      confirmacaoSenha: 'Password Confirmation',
      buttonVoltar: 'Come back',
      buttoCriar: 'Create an account',
      tituloPoliticaPrivacidade: 'Privacy Policy',
      buttonAceitoPoliticaPrivacidade: 'I accept the Privacy Policy',
      validacoes: {
        cpf: 'Invalid CPF',
        cpfObrigatorio: 'Inform the CPF',
        nome: 'Enter full name',
        dataAniversario: 'Enter date of birth',
        dataAniversarioMaior: 'Date of birth greater than the current date',
        telefoneCelular: 'Inform the cell phone',
        email: 'Invalid email',
        sexo: 'Inform the Gender',
        emailObrigatorio: 'Enter the email',
        senha: 'Enter the password',
        confirmacaoSenha: 'Enter password confirmation',
        senhasDiferentes: 'Passwords do not match',
        senhaInvalida: 'invalid password'
      },
      sendCode: {
        titulo: 'We need to validate your account',
        subtitulo: 'How do you want to get the validation code?',
        button: 'To send',
        email: 'Send message to email',
        sms: 'Send message to cell phone',
        codigoEnviadoSucesso: 'Code sent successfully!'
      }
    },
    forgot: {
      titulo: 'Redefine password',
      subtitulo: 'Enter your email or phone number to reset your password.',
      placeholderEmail: 'Enter your email here',
      placeholderCelular: 'Enter your cell phone here',
      button: 'Continue',
      buttonCancelar: 'Cancel',
      emailValido: 'Enter a valid email address',
      ou: 'Or'
    },
    sendCode: {
      titulo: 'We need to verify your identity',
      subtitulo: 'How do you want to receive the security code to reset your password?',
      button: 'To send',
      buttonOutlined: 'No, and you?',
      email: 'Email the code',
      sms: 'Send code by SMS'
    },
    authVerifyCode: {
      titulo: 'Check your ',
      subtitulo: 'We send a confirmation code to the ',
      email: 'email',
      telefone: 'telephone',
      sms: 'sms',
      placeholder: 'Enter the validation code here',
      button: 'Confirm',
      auxilio: 'If you do not receive the ',
      auxilio2: ', you can submit another request at:',
      buttonOutlined: 'Resend code',
      buttonTentar: 'Try another way',
      obrigatorio: 'Enter the validation code',
      sucesso: 'Code successfully resent!'
    },
    changePassword: {
      titulo: 'Change the password',
      subtitulo: 'Enter the desired new access password below',
      senha: 'Password'
      ,
      confirmacaoSenha: 'Password Confirmation',
      button: 'Confirm',
      senhaAlterada: 'Password changed successfully!',
      validacoes: {
        senha: 'Enter the password',
        minimoCaracteres: 'Password must have at least 8 characters',
        confirmacaoSenha: 'Inform password confirmation',
        senhasDiferentes: 'Passwords do not match',
        senhaInvalida: 'Invalid password'
      }
    },
    historico: {
      titulo: 'Historic',
      clinica: 'Clinic',
      profissional: 'Professional',
      especialidade: 'Specialty',
      duracao: 'Duration',
      status: 'Status',
      operadoraPlano: 'Health and Plan Operator',
      paciente: 'Patient',
      tipoAgendamento: 'Scheduling Type',
      orientacoesExames: 'Exam Guidelines',
      orientacoesClinica: 'Clinic guidelines',
      valor: 'Value',
      cancelar: 'Cancel',
      nenhumAgendamento: 'No appointment found',
      desejaCancelar: 'Do you really want to cancel the appointment?',
      voltar: 'Come back',
      confirmar: 'Confirm',
      agendamentoCancelado: 'Appointment canceled successfully!'
    },
    expiredPassword: {
      titulo: 'Password change request',
      subtitulo:
        'According to the resolutions of the Federal Council of Medicine, you must change your password within a period of 30 to 180 days or when requested by an administrator',
      senhaAtual: 'Current password',
      novaSenha: 'New Password',
      confirmacaoSenha: 'New Password',
      solicitarTroca: 'Request password change',
      acessar: 'Access',
      senhaAlterada: 'Password changed successfully!',
      validacoes: {
        senhaAtual: 'Enter current password',
        novaSenha: 'Enter the new password',
        confirmacaoSenha: 'Enter password confirmation',
        senhasDiferentes: 'Passwords do not match',
        solicitarTroca: 'Enter the days to request a new password change'
      }
    },
    perfil: {
      salvar: 'To save',
      cancelar: 'Cancel',
      sucesso: 'Data saved successfully',
      dadosBasicos: {
        tab: 'basic data',
        tituloDados: 'DATA',
        subtituloDados: 'Key customer data',
        nome: 'Full name',
        dataNascimento: 'Date of birth',
        cpf: 'CPF',
        nomeSocial: 'Social name',
        tituloContato: 'CONTACT',
        subtituloContato: 'Inform the main contact',
        celular: 'Cell phone',
        telefone: 'Landline',
        email: 'Email',
        naoReceberSMS: 'I don t want to receive Corporate SMS',
        naoReceberSMSMarketing: 'I don t want to receive SMS Marketing',
        naoReceberEmail: 'I don t want to receive email',
        tituloNecessidadesEspeciais: 'SPECIAL NEEDS',
        subtituloNecessidadesEspeciais: 'Do you have any special needs?',
        tituloResponsaveis: 'RESPONSIBLE',
        tituloDependentes: 'DEPENDENTS',
        remover: 'To remove',
        desejaRemover: 'Do you really want to remove?',
        responsavelRemovido: 'Responsible removed successfully',
        dependenteRemovido: 'Dependent successfully removed',
        necessidadesEspeciais: {
          obeso: 'Fat',
          gestante: 'Pregnant',
          lactanteCriancaColo: 'Infant and/or infant',
          deficiencia: 'Handicapped',
          idoso: 'Elderly'
        },
        validacoes: {
          cpf: 'Invalid CPF',
          cpfObrigatorio: 'Inform the CPF',
          nome: 'Enter full name',
          dataNascimento: 'Enter date of birth',
          dataNascimentoMaior: 'Date of birth greater than current date'
        }
      },
      documentos: {
        selecioneGrupo: 'Select a group to add',
        tituloRegistroGeral: 'GENERAL REGISTRATION',
        rg: 'RG',
        emissor: 'Issuer',
        rgEmissao: 'Shipping date',
        nomeMae: 'Mother s name',
        nomePai: 'Father s name',
        naturalidade: 'Naturalness',
        tituloPassaporte: 'PASSPORT',
        pais: 'Country',
        numeroPassaporte: 'Passport',
        dataEntradaBrasil: 'Entry date',
        tituloEscolaridade: 'SCHOOLING',
        escolaridade: 'Scholarity',
        tituloTituloEleitor: 'VOTER S TITLE',
        tituloEleitor: 'Voter registration card',
        zonaEleitor: 'Electoral zone',
        sessaoEleitor: 'Section'
      },
      endereco: {
        tituloDados: 'DATA',
        cep: 'Zip code',
        pais: 'Country',
        cidade: 'City',
        uf: 'State',
        logradouro: 'Public place',
        tipoLogradouro: 'Street type',
        endereco: 'Address',
        numero: 'Number',
        bairro: 'Neighborhood',
        complemento: 'Complement'
      },
      acesso: {
        tituloAlterarSenha: 'CHANGE PASSWORD',
        senhaAtual: 'Current password',
        novaSenha: 'New Password',
        confirmacaoSenha: 'Password confirmation',
        alterarSenha: 'CHANGE PASSWORD',
        tituloTrocaSenha: 'CHANGE PASSWORD',
        solicitarTrocaSenha: 'Request password change',
        tituloDadosAcessoPortal: 'ACCESS DATA',
        email: 'Email',
        cpf: 'CPF',
        inativarAcessoPortal: 'DISABLE ACCESS LOGIN',
        mensagemInativarAcessoPortal:
          'Dear patient, by inactivating the login you will no longer be able to make appointments with this user. Do you really want to disable login?',
        inativar: 'Inactivate',
        solicitarNovaSenhaEmDias: {
          30: '30 days',
          60: '60 days',
          90: '90 days',
          180: '180 days'
        },
        validacoes: {
          senhaAtual: 'Enter current password',
          novaSenha: 'Enter the new password',
          confirmacaoSenha: 'Enter password confirmation',
          senhasDiferentes: 'Passwords do not match',
          solicitarTroca: 'Enter the days to request a new password change',
          senhaAlterada: 'Password changed successfully!',
          senhaInvalida: 'Invalid password'
        }
      }
    },
    teleconsultas: {
      teleconsulta: 'Teleconsultation'
    }
  }
}
