import { mutationTrocarSenha, mutationLogar, queryEu } from 'api/portal'

export function getToken() {
  return window.localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_KEY)
}

export function setToken(token) {
  return window.localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_KEY, token)
}

export function removeToken() {
  return window.localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_KEY)
}

export function getExpireDate() {
  return window.localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_EXP)
}

export function setExpireDate(date) {
  return window.localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_EXP, date)
}

export function removeExpireDate() {
  return window.localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_EXP)
}

export function bootstrap() {
  const token = getToken()

  if (!token) {
    return Promise.resolve(null)
  }

  return queryEu().catch(() => Promise.resolve(null))
}

function authenticate(params = {}) {
  const handleLogin = mutationLogar()

  return handleLogin(params).then((data) => {
    if (data.result.token) {
      setToken(data.result.token)
      setExpireDate(data.result.dataExpiracao)
      return data.result.eu
    }
    return data.result
  })
}

export function login({ identificador, senha }) {
  return authenticate({ identificador, senha }).then(bootstrap)
}

export async function changePassword({ email, senhaAtual, novaSenha, solicitarNovaSenhaEm }) {
  const handleTrocarSenha = mutationTrocarSenha()

  return handleTrocarSenha({ email, senhaAtual, novaSenha, solicitarNovaSenhaEm }).then((data) => {
    if (data.token) {
      setToken(data.token)
      setExpireDate(data.dataExpiracao)
      return data.eu
    }
    return data
  })
}

export async function changeEu(data) {
  if (data.token) {
    setToken(data.token)
    setExpireDate(data.dataExpiracao)
    return data.eu
  }
  return data
}

export function logout() {
  removeToken()
  removeExpireDate()
  return Promise.resolve(null)
}
