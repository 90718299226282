export const barcode = (boletoNumber) => {
  return boletoNumber.replace(/^(\d{4})(\d{5})\d{1}(\d{10})\d{1}(\d{10})\d{1}(\d{15})$/, '$1$5$2$3$4')
}

export const calculusChecker = (barcodeDigits) => {
  let digits = barcodeDigits

  if (typeof digits === 'string') {
    digits = digits.split('')
  }
  digits.reverse()

  let sum = 0

  for (let i = 0; i < digits.length; i += 1) {
    sum += ((i % 8) + 2) * digits[i]
  }

  return (11 - (sum % 11)) % 10 || 1
}

export const number = (boletoNumber) => boletoNumber.replace(/[^0-9]+/gi, '')

export const boletoValidator = (boletoNumber) => {
  const boletoFormatted = number(boletoNumber)
  if (boletoFormatted.length !== 47) return false
  const barcodeDigits = barcode(boletoFormatted).split('')
  const checkerCode = barcodeDigits.splice(4, 1)

  return calculusChecker(barcodeDigits).toString() === checkerCode.toString()
}

export const preetyNumber = (boletoNumber) => {
  return boletoNumber.replace(/^(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})$/, '$1.$2 $3.$4 $5.$6 $7 $8')
}

export const boletoAmount = (boletoNumber) => {
  const boletoFormatted = number(boletoNumber)
  return (barcode(boletoFormatted).substr(9, 10) / 100.0).toFixed(2)
}

export const bank = (boletoNumber) => {
  const boletoFormatado = number(boletoNumber)
  const codBank = barcode(boletoFormatado).substr(0, 3)
  if (codBank === '001') return 'Banco do Brasil'
  if (codBank === '007') return 'BNDES'
  if (codBank === '033') return 'Santander'
  if (codBank === '069') return 'Crefisa'
  if (codBank === '077') return 'Banco Inter'
  if (codBank === '102') return 'XP Investimentos'
  if (codBank === '104') return 'Caixa Econômica Federal'
  if (codBank === '140') return 'Easynvest'
  if (codBank === '197') return 'Stone'
  if (codBank === '208') return 'BTG Pactual'
  if (codBank === '212') return 'Banco Original'
  if (codBank === '237') return 'Bradesco'
  if (codBank === '260') return 'Nu Pagamentos'
  if (codBank === '314') return 'Itaú'
  if (codBank === '389') return 'Banco Mercantil do Brasil'
  if (codBank === '422') return 'Banco Safra'
  if (codBank === '505') return 'Credit Suisse'
  if (codBank === '633') return 'Banco Rendimento'
  if (codBank === '652') return 'Itaú Unibanco'
  if (codBank === '735') return 'Banco Neon'
  if (codBank === '739') return 'Banco Cetelem'
  if (codBank === '745') return 'Citibank'
  return 'Unknown'
}

export const currency = (boletoNumber) => {
  switch (barcode(boletoNumber)[3]) {
    case '9':
      return { code: 'BRL', symbol: 'R$', decimal: ',' }
    default:
      return 'Unknown'
  }
}

export const checkerCode = (boletoNumber) => {
  return barcode(boletoNumber)[4]
}

export const dateDue = (boletoNumber) => {
  const refDate = new Date(876236400000) // 1997-10-07 12:00:00 GMT-0300
  const days = barcode(boletoNumber).substr(5, 4)

  return new Date(refDate.getTime() + days * 86400000)
}

export const ValueBoletoformatted = (boletoNumber) => {
  const currencyBoleto = currency(boletoNumber)

  if (currencyBoleto === 'Unknown') return boletoAmount(boletoNumber)
  return `${currencyBoleto.symbol} ${boletoAmount(boletoNumber).replace('.', currencyBoleto.decimal)}`
}
