import React, { useState } from 'react'

import { ThemeProvider } from 'styled-components'

export const SptThemeProvider = ({ theme: defaultTheme = {}, children }) => {
  const [theme, setTheme] = useState(defaultTheme)

  return (
    <ThemeProvider
      theme={{
        ...theme,
        setTheme
      }}
    >
      {children}
    </ThemeProvider>
  )
}
