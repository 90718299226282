import React, { Suspense, lazy } from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'

import { SptLoading } from '@sponte/lib-components/dist/atoms/Loading'

const Agendamento = lazy(() => import('./pages/agendamento'))
const Teleconsulta = lazy(() => import('./pages/teleconsulta'))

const App = () => {
  return (
    <Suspense fallback={<SptLoading full size="lg" p={18} />}>
      <Switch>
        <Route path="/agendamento" component={Agendamento} />
        <Route path="/teleconsulta" component={Teleconsulta} />
        <Route render={() => <Redirect to="/agendamento" />} />
      </Switch>
    </Suspense>
  )
}

export default App
