export const prop = (path, defaultValue) => (props = {}) => {
  if (typeof props[path] !== 'undefined') {
    return props[path]
  }

  if (path && path.indexOf('.') > 0) {
    const paths = path.split('.')
    const { length } = paths
    let object = props[paths[0]]
    let index = 1

    while (object != null && index < length) {
      object = object[paths[index]]
      index += 1
    }

    if (typeof object !== 'undefined') {
      return object
    }
  }

  return defaultValue
}

export const switchProp = (needle, cases, defaultCase) => (props = {}) => {
  const value = typeof needle === 'function' ? needle(props) : prop(needle)(props)
  const finalCases = typeof cases === 'function' ? cases(props) : cases

  if (value in finalCases) {
    return finalCases[value]
  }

  return defaultCase
}

const parseFunction = (props, test) => Boolean(test(props))

const parseObject = (props, test) => {
  const keys = Object.keys(test)
  const { length } = keys

  for (let index = 0; index < length; index += 1) {
    const key = keys[index]
    const expected = test[key]
    const value = prop(key)(props)

    if (expected !== value) {
      return false
    }
  }

  return true
}

const parseString = (props, test) => Boolean(prop(test)(props))

const parseMap = {
  function: parseFunction,
  object: parseObject,
  string: parseString
}

export const ifProp = (test, pass = '', fail = '') => (props = {}) => {
  let result = true

  if (Array.isArray(test)) {
    const { length } = test
    let index = 0

    while (result && index < length) {
      result = parseMap[typeof test[index]](props, test[index])
      index += 1
    }
  } else {
    result = parseMap[typeof test](props, test)
  }

  const value = result ? pass : fail

  return typeof value === 'function' ? value(props) : value
}

export const ifNotProp = (test, pass, fail) => ifProp(test, fail, pass)

export const theme = (path, defaultValue) => (props) => prop(path, defaultValue)(props.theme)

const paletteTones = ['main', 'light', 'dark']
const paletteColors = ['primary', 'secondary', 'info', 'success', 'warning', 'error']
const isPaletteTone = (key) => paletteTones.includes(key)
const isPaletteColor = (key) => paletteColors.includes(key)

export const palette = (colorOrTone, toneOrDefaultValue, defaultValue) => (props) => {
  const isTone = isPaletteTone(colorOrTone)
  const isColor = isPaletteColor(colorOrTone)
  const color = isColor ? colorOrTone : props.palette

  let tone

  if (isTone) {
    tone = colorOrTone
  } else if (isPaletteTone(toneOrDefaultValue)) {
    tone = toneOrDefaultValue
  } else {
    tone = props.tone || 'main'
  }

  const finalDefaultValue = toneOrDefaultValue !== tone ? toneOrDefaultValue : defaultValue

  if (!props.theme.palette || !props.theme.palette[color] || !props.theme.palette[color][tone]) {
    return finalDefaultValue
  }

  return props.theme.palette[color][tone]
}

export const fontSize = (value) => {
  const size = typeof value === 'number' ? value.toString() : value

  return (props) => {
    const val = props.fontSize || size || 'default'
    const fs = theme(`fontSizes.${val}`)(props)
    const lineHeightScale = theme('lineHeightScale')(props)

    if (!fs) {
      return {}
    }

    return {
      fontSize: `${fs}px`,
      lineHeight: `${fs + lineHeightScale}px`
    }
  }
}
